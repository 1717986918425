import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { useLocation } from "@hooks/use-location"
import { klipfolioPublisher } from "./common"

const MetricStackSchema = ({ episode }) => {
  const location = useLocation()
  const seo = episode.seo

  // Add length, and summary
  const schema = {
    "@context": "https://schema.org/",
    "@type": "PodcastEpisode",
    url: location.buildHref,
    name: seo.title,
    description: seo.description,
    datePublished: episode.date_published,
    episodeNumber: episode.episode_number,
    productionCompany: klipfolioPublisher,
    sameAs: [
      episode.spotify_link,
      episode.apple_music_link,
      episode.youtube_link,
    ],
    associatedMedia: {
      "@type": "MediaObject",
      contentUrl: episode.apple_music_link,
    },
    partOfSeries: {
      "@type": "PodcastSeries",
      name: "Metric Stack",
      url: "https://www.klipfolio.com/metric-stack",
    },
    director: [
      {
        "@type": "Person",
        name: "Allan Wille",
        jobTitle: "CEO of Klipfolio",
        sameAs: [
          "https://ca.linkedin.com/in/allanwille",
          "https://twitter.com/awille",
        ],
      },
      {
        "@type": "Person",
        name: "Lauren Thibodeau",
        jobTitle: "CEO of Klipfolio",
        sameAs: [
          "https://ca.linkedin.com/in/laurenthibodeau",
          "https://twitter.com/lathib",
        ],
      },
    ],
    actor: [
      {
        "@type": "Person",
        name: episode.guest_name,
        jobTitle: episode.job_title,
      },
    ],
  }

  if (seo?.schema) {
    Object.assign(schema, JSON.parse(seo.schema))
  }

  return (
    <Helmet>
      <script type="application/ld+json">{`${JSON.stringify(schema)}`}</script>
    </Helmet>
  )
}

MetricStackSchema.propTypes = {
  episode: PropTypes.object.isRequired,
}

export default MetricStackSchema
