import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/layout"
import PropTypes from "prop-types"
import Container from "@components/layout/container"
import styled from "styled-components"
import Html from "@components/html"
import Breadcrumb from "@components/blog/breadcrumb"
import Heading from "@components/elements/heading"
import media from "@styles/media"
import Grid from "@components/elements/grid"
import Image from "@components/image"
import MetricStackSchema from "@components/schema/metric-stack"
import theme from "../../styles/theme"
import { processImages } from "@utils/process-images"
import Flex from "@components/elements/flex"
import Anchor from "@components/elements/anchor"
import Paragraph from "@components/elements/paragraph"

const MetricStackContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 2fr;
  margin: 0 auto;

  ${media.sm`
    margin-top: 6rem;
    grid-template-columns: 1fr;
  `}
`

const LeftContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  ${media.sm`
    border-radius: 2rem;
    overflow: hidden;
  `}
`

const RightContainer = styled.div`
  flex-flow: column;
  padding: 1rem 3rem 6rem;
  justify-content: initial;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 4rem;
  h3 {
    margin-top: 1rem;
  }

  ${media.sm`
    margin: 0;
    padding: 2rem 0;
  `}
`

const Stripes = styled.div`
  top: 0;
  display: flex;
  flex-flow: row;
  width: 100%;
  z-index: 0;
`

const Stripe = styled.div`
  display: flex;
  width: 20%;
  height: 100%;
  background-color: ${props => props.color || "black"};
  background-image: linear-gradient(
    to bottom,
    rgba(${props => props.color || "0, 0, 0"}, 255) 0%,
    rgba(${props => props.color || "0, 0, 0"}, 255) 50%,
    rgba(${props => props.color || "0, 0, 0"}, 0)
  );
`

const Foreground = styled.div`
  flex-flow: column;
  height: 100%;
  justify-content: initial;
  margin-left: -100%;
  width: 100%;
  padding: 3rem;
  flex: none;
  display: flex;
  align-items: center;
  margin-top: 4rem;
`

const GuestCard = styled.div`
  position: sticky;
  top: 6rem;
  color: #fff;
  width: 100%;
  transition: 0.5s all;
`

const Wrapper = styled.div`
  blockquote {
    padding: 2rem;
    display: flex;
    gap: 1rem;
    flex-flow: column;
    text-align: left;
    margin: 0;
  }
`

const MetricStackEpisode = ({ data }) => {
  const episode = data.episode
  const image = episode.guest_image
  const imageUrl = image?.cdn
  const withGuest = `With ${episode.guest_name}, ${episode.job_title}`
  const images = processImages(data.images.edges)

  return (
    <Layout
      image={imageUrl}
      fullWidth={true}
      title={`${episode.title} ${withGuest}`}
      description={
        "Hosted by Allan Wille and Lauren Thibodeau, the Metric Stack podcast is the place to hear stories from founders, leaders, marketers, and more as they share how they succeed with data."
      }
      marginless
      seo={episode.seo}
    >
      <MetricStackSchema episode={episode} />
      <Container fullWidth maxWidth="1300px">
        <MetricStackContainer>
          <LeftContainer>
            <Stripes>
              <Stripe color="50, 173, 168" />
              <Stripe color="239, 201, 54" />
              <Stripe color="211, 86, 56" />
              <Stripe color="101, 81, 170" />
              <Stripe color="126, 165, 240" />
            </Stripes>
            <Foreground>
              <GuestCard>
                {episode.guest_image && (
                  <Image
                    objectFit="scale-down"
                    radius={1}
                    aspectRatio="1/1"
                    file={episode.guest_image}
                    eager={true}
                    borderStyle={{ boxShadow: theme.shadow.default }}
                  />
                )}
              </GuestCard>
            </Foreground>
          </LeftContainer>
          <RightContainer>
            <Breadcrumb
              noPadding
              links={[
                {
                  text: "Metric Stack",
                  url: "/metric-stack",
                },
                {
                  text: `Episode ${episode.episode_number}`,
                },
              ]}
            />
            <Heading margin="1rem 0 0.5rem">{episode.title}</Heading>
            <Heading as="h2" fontSize="1.3rem" margin="0 0 2rem">
              {withGuest}
            </Heading>
            <Paragraph margin="0 0 0.5rem" fontWeight="700">
              Listen:
            </Paragraph>
            <Flex
              gap="0.75rem"
              flexFlow="row"
              width="fit-content"
              margin="0 0 2rem"
            >
              <Anchor link={episode.spotify_link}>
                <Image
                  height={42}
                  width={42}
                  file={images["ddcffab5-6cbf-4800-8071-cd477671f692"]}
                />
              </Anchor>
              <Anchor link={episode.apple_music_link}>
                <Image
                  height={42}
                  width={42}
                  file={images["7fb6dbcf-8fdb-49f8-8909-56a835285332"]}
                />
              </Anchor>
              <Anchor link={episode.youtube_link}>
                <Image
                  height={42}
                  width={42}
                  file={images["ec5539b8-6b3a-4ded-abf2-134dd375a812"]}
                />
              </Anchor>
            </Flex>
            <Wrapper>
              <Grid gap="2rem" fontSize="1.2rem">
                <Html html={episode.body} parseElements />
              </Grid>
            </Wrapper>
          </RightContainer>
        </MetricStackContainer>
      </Container>
    </Layout>
  )
}

MetricStackEpisode.propTypes = {
  data: PropTypes.object.isRequired,
}

export default MetricStackEpisode

export const pageQuery = graphql`
  query MetricStackEpisodeQuery($id: String!) {
    images: allDirectusFiles(
      filter: {
        directus_id: {
          in: [
            "ddcffab5-6cbf-4800-8071-cd477671f692"
            "7fb6dbcf-8fdb-49f8-8909-56a835285332"
            "ec5539b8-6b3a-4ded-abf2-134dd375a812"
          ]
        }
      }
    ) {
      edges {
        node {
          directus_id
          cdn
          id
          placeholder
          title
        }
      }
    }
    episode: metricStackEpisode(id: { eq: $id }) {
      apple_music_link
      body
      body_images {
        cdn
        placeholder
        id
        title
        directus_id
      }
      date_updated
      episode_number
      guest_image {
        id
        title
        cdn
        placeholder
      }
      title
      spotify_link
      job_title
      guest_name
      user_created
      user_updated
      youtube_link
      seo {
        ...seoData
      }
    }
  }
`
